body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.comment-body * {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  white-space: pre-wrap;
  hyphens: none;
}
.story-item * {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  white-space: pre-wrap;
  hyphens: none;
}

.comment-body p + p {
  margin-top: 10px;
}

.comment-body a {
  font-weight: bold;
}

.comment-body a:hover {
  text-decoration: underline;
}

.story-item a:hover {
  text-decoration: underline;
}
